import { BenchmarkOption } from '@components/EventContent'
import { CustomEventFilterGroups } from '@components/EventContent/InsightsAndTrends/TimeSeriesChartCard/CustomEventsBenchmark'
import { EventSearchContext } from '@stores/eventSearchStore'
import { Filters as PredictionAssessmentFilters } from '@stores/predictionAssessmentStore'
import { EventFilter, SecondaryMarketChartType } from '@stores/slices/eventDataSlice'
import { CompetitorsExclusiveFilters } from '@stores/slices/eventDataSlice/competingEvents/types'
import { Filters as TourMarketingFilters } from '@stores/tourMarketingStore'
import { Filters as TourPlanningFilters } from '@stores/tourPlanningStore'

export type BookmarkPage =
  | BookmarkPageEnum.EVENT_INSIGHTS
  | BookmarkPageEnum.BRAND_VENUE_INSIGHTS
  | BookmarkPageEnum.PREDICTION_ASSESSMENT
  | BookmarkPageEnum.EVENT_ADVANCED_LIST
  | BookmarkPageEnum.EVENT_ADVANCED_SINGLE
  | BookmarkPageEnum.BRAND_VENUE_INSIGHTS_SINGLE

export type TourPlanningContext = {
  filters: TourPlanningFilters
  selectedEvents: number[]
  singleEventFilter: EventFilter | null
  benchmark?: {
    option: BenchmarkOption
    events?: CustomEventFilterGroups
  }
}

export type TourMarketingContext = {
  filters: TourMarketingFilters
  benchmark?: {
    option: BenchmarkOption
    events?: CustomEventFilterGroups
  }
  competitorsFilters?: CompetitorsExclusiveFilters
  secondaryMarket: {
    chartType: SecondaryMarketChartType
  }
}

export type PredictionAssessmentContext = {
  filters: PredictionAssessmentFilters
}

export type BookmarkContext =
  | TourPlanningContext
  | TourMarketingContext
  | PredictionAssessmentContext
  | EventSearchContext

export type BookmarkSerializedData = {
  page: BookmarkPage
  context: BookmarkSerializedContext
}

export type BookmarkSerializedContext = any

export type Bookmark = {
  uid?: string
  data: BookmarkSerializedData
  name: string
  checksum: string
}

export enum BookmarkPageEnum {
  EVENT_INSIGHTS = 'event-insights',
  BRAND_VENUE_INSIGHTS = 'artist-venue-insights',
  PREDICTION_ASSESSMENT = 'prediction-assessment',
  EVENT_ADVANCED_LIST = 'event-advanced-list',
  EVENT_ADVANCED_SINGLE = 'event-advanced-single',
  BRAND_VENUE_INSIGHTS_SINGLE = 'artist-venue-insights-single',
}

export type BookmarkData = {
  page: BookmarkPage
  context: BookmarkContext
}

export type CreateBookmarkExtraData = {
  nameCreator?: any
}

export type Serializer = (ctx: BookmarkContext) => BookmarkSerializedContext

export type Deserializer = (json: any) => BookmarkContext

export type QueryParamsGenerator = (ctx: BookmarkContext) => URLSearchParams | string

export type { EventSearchContext }
