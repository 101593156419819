import { ChangeEvent, useCallback, useState } from 'react'
import { Checkbox, Label, Textarea } from 'carbonarc-ui'
import { useTranslation } from 'react-i18next'
import { ConfirmationModal } from '@components/Modal'
import { useEffectOnce } from '@hooks/useEffectOnce'
import { downloadPDFInNewTab } from '@utils/pdf'
import { CheckboxOption } from './ExportButton'

interface ExportModalProps {
  show: boolean
  setShow: (show: boolean) => void
  pageId: string
  options: CheckboxOption[]
}

export function ExportModal(props: ExportModalProps) {
  const { show, setShow, pageId, options = [] } = props
  const { t } = useTranslation('tour_marketing')
  const [optionsChecked, setOptionsChecked] = useState<Record<string, boolean> | null>(null)
  const [comment, setComment] = useState('')
  useEffectOnce(() => {
    const optionsChecked = options.reduce(
      (obj, option) => ({
        ...obj,
        [option.id]: !option.disabled && option.checked !== false,
      }),
      {},
    )

    setOptionsChecked(optionsChecked)
  })

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOptionsChecked((previousOptionsChecked) => ({
      ...previousOptionsChecked,
      [e.target.id]: e.target.checked,
    }))
  }

  const handleConfirm = useCallback(() => {
    const pdfReportHideParams = Object.entries(optionsChecked || {})
      .filter(([, checked]) => !checked)
      .map(([key]) => key)

    downloadPDFInNewTab(pageId, pdfReportHideParams, comment)
    setShow(false)
  }, [optionsChecked, pageId, setShow, comment])

  return (
    <ConfirmationModal
      show={show}
      setShow={setShow}
      title={t('common:export.report')}
      confirmButton={{
        label: t('common:export.title'),
        onClick: handleConfirm,
        disabled: Object.values(optionsChecked || []).every((option) => !option),
      }}
      cancelButton={{ label: t('common:cancel'), variant: 'outline', onClick: () => setShow(false) }}
    >
      <div className="flex flex-col gap-4">
        <p className="leading-tight text-xs font-normal text-gray-500 dark:text-gray-200 mb-3">
          {t('common:export.description')}
        </p>

        <div className="flex flex-col gap-3">
          {optionsChecked &&
            options.map((option) => (
              <div key={option.id} className="flex items-center">
                <Checkbox
                  id={option.id}
                  disabled={option.disabled}
                  onChange={handleCheckboxChange}
                  checked={optionsChecked[option.id]}
                />
                <Label
                  htmlFor={option.id}
                  disabled={option.disabled}
                  className="leading-none text-sm font-medium text-gray-900 ml-2 disabled:text-gray-400"
                >
                  {option.label}
                </Label>
              </div>
            ))}
        </div>
        <Textarea
          rows={4}
          required
          maxLength={500}
          placeholder={`Please leave any comments or remarks here to be exported with your PDF`}
          onChange={(event) => {
            setComment(event.target.value)
          }}
        />
      </div>
    </ConfirmationModal>
  )
}
