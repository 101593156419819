import { ChangeEvent, useCallback } from 'react'
import { Checkbox, Tooltip } from 'carbonarc-ui'
import isEmpty from 'lodash/isEmpty'
import { useTranslation } from 'react-i18next'
import { Table } from '@components/Table'
import { VisualizationId } from '@contexts/VisualizationEvaluation/types'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid'
import { useTourPlanningDataAvailabilityPlaceholder } from '@hooks/TourPlanning/useTourPlanningDataAvailabilityPlaceholder'
import { PageData } from '@hooks/usePaginatedData'
import { useSearchParamsObject } from '@hooks/useSearchParamsObject'
import { useSingleEventStore } from '@stores/singleEventStore'
import { useTourPlanningStore } from '@stores/tourPlanningStore'
import { filtersToQueryParams, listToQueryParam } from '@stores/tourPlanningStore/queryParams'
import { StarEventEntity } from '@types'
import { formatUtils } from '@utils'
import { cn } from '@utils/className'
import { toISOStringDatePart } from '@utils/date'
import { formatCityState } from '@utils/format'

interface HistoricalSummaryProps {
  events: PageData<StarEventEntity>
}

export function HistoricalSummary({ events }: HistoricalSummaryProps) {
  const { t } = useTranslation('tour_planning')
  const searchParamsHandler = useSearchParamsObject()

  const {
    isLoading,
    selectedEventIds,
    addSelectedEvents,
    removeSelectedEvents,
    stringfiedFilterContext,
    pdfHeader,
    filterContext,
    page,
    exportOptions,
  } = useTourPlanningStore((state) => ({
    isLoading: state.isLoading,
    selectedEvents: state.selectedEvents,
    selectedEventIds: state.selectedEventIds,
    addSelectedEvents: state.addSelectedEvents,
    removeSelectedEvents: state.removeSelectedEvents,
    stringfiedFilterContext: state.stringfiedFilterContext,
    pdfHeader: state.pdfHeader,
    filterContext: state.filterContext,
    page: state.page,
    exportOptions: state.exportOptions,
  }))
  const { DataPlaceholderIcon, DataPlaceholderMessage } = useTourPlanningDataAvailabilityPlaceholder()
  const { selectEvent } = useTourPlanningStore((state) => ({
    selectEvent: state.selectEvent,
  }))
  const { selectEvent: selectSingleEvent } = useSingleEventStore((state) => ({
    selectEvent: state.selectEvent,
  }))
  const handleSelectAllEvents = (e: ChangeEvent<HTMLInputElement>) => {
    if (!events.items) return

    const handlingEventIds = events.items.map((ev) => ev.id)
    if (e.target.checked) {
      addSelectedEvents(handlingEventIds, searchParamsHandler)
    } else {
      removeSelectedEvents(handlingEventIds, searchParamsHandler)
    }
  }

  const handleSelectSingleEvent = (event: StarEventEntity, isSelected: boolean) => {
    if (isSelected) {
      removeSelectedEvents([event.id], searchParamsHandler)
    } else {
      addSelectedEvents([event.id], searchParamsHandler)
    }
  }

  const handleEventClick = useCallback(
    (event: StarEventEntity) => {
      if (event.brand?.id && event.venue?.id && event.performance_date) {
        selectEvent(
          {
            artist: event.brand.id.toString(),
            venue: event.venue.id.toString(),
            date: toISOStringDatePart(event.performance_date),
          },
          searchParamsHandler,
        )
        selectSingleEvent({
          artist: event.brand.id.toString(),
          venue: event.venue.id.toString(),
          date: toISOStringDatePart(event.performance_date),
        })
      }
    },
    [selectEvent, selectSingleEvent, searchParamsHandler],
  )

  const allSelected = events.items.every((event) => selectedEventIds.includes(event.id))
  const id = 'pdf-report-hide-historical-summary'
  return (
    <>
      <div className="flex flex-col gap-2 mb-5 md:mb-6">
        <h2 className="text-xl md:text-3xl font-semibold dark:text-white">{t('historical_summary_table.title')}</h2>
        <h3 className="text-sm md:text-base font-normal text-gray-500 dark:text-gray-400">
          {t('historical_summary_table.subtitle')}
        </h3>
      </div>
      <div className={`mb-4 group-[.pdf-report-hide-historical-summary]:hidden`}>
        <Table
          id={VisualizationId.HistoricalSummary}
          visualization={VisualizationId.HistoricalSummary}
          header={[
            {
              column: <Checkbox checked={allSelected} onChange={handleSelectAllEvents} />,
              className: 'w-12',
            },
            { column: t('historical_summary_table.column_artist') },
            { column: t('historical_summary_table.column_venue') },
            { column: t('historical_summary_table.column_event_date') },
            { column: t('historical_summary_table.column_venue_city_state') },
            { column: t('historical_summary_table.column_venue_capacity') },
            { column: t('historical_summary_table.column_sellable_capacity') },
            { column: t('historical_summary_table.column_artist_net_revenue') },
            { column: t('historical_summary_table.column_quantity_sold') },
            { column: t('historical_summary_table.column_percent_sold') },
            { column: t('historical_summary_table.column_secondary_market') },
          ]}
          body={events.items.map((event) => {
            const isSelected = selectedEventIds.includes(event.id)

            const cityState = formatCityState(event.venue?.location?.city, event.venue?.location?.state)

            return {
              row: [
                <Checkbox checked={isSelected} onChange={() => handleSelectSingleEvent(event, isSelected)} />,
                <Tooltip content="Explore event insights with competing events">
                  <div
                    className="text-blue-600 dark:text-blue-500 text-sm font-medium hover:bg-gray-200 dark:hover:bg-slate-800 rounded-md"
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      handleEventClick(event)
                    }}
                  >
                    {event.brand?.name}
                  </div>
                </Tooltip>,
                event.venue?.name,
                formatUtils.formatDateNumbersOnly(event.performance_date),
                cityState,
                event.venue?.capacity == null ? '-' : formatUtils.formatNumber(event.venue?.capacity),
                event.sellable_cap == null ? '-' : formatUtils.formatNumber(event.sellable_cap),
                event.brand_net == null ? '-' : formatUtils.formatCurrency(event.brand_net),
                event.sales_summary?.current_quantity_sold == null
                  ? '-'
                  : formatUtils.formatNumber(event.sales_summary.current_quantity_sold),
                event.sales_summary?.current_percent_sold == null
                  ? '-'
                  : formatUtils.formatPercentage(event.sales_summary.current_percent_sold / 100),
                event?.has_secondary_market ? (
                  <CheckCircleIcon className="w-7 h-7 text-green-400" />
                ) : (
                  <XCircleIcon className="w-7 h-7 text-red-400" />
                ),
              ].map((item) => (isEmpty(item) ? '-' : item)),
              className: cn('hover:bg-blue-50/50 cursor-pointer', isSelected ? ' font-semibold' : 'font-normal'),
              onClick: () => handleSelectSingleEvent(event, isSelected),
            }
          })}
          showTable={events.total > 0}
          isLoading={isLoading || events.fetching}
          totalLabel="All Shows"
          totalPostfix="(Click on Artist name to view event insights and competing events)"
          csvDownload={
            filterContext
              ? {
                  filename: `historical_summary-${stringfiedFilterContext}`,
                  apiUrl: `/data/eventsummarylist/download?${filtersToQueryParams(filterContext).toString()}`,
                }
              : undefined
          }
          csvDownloadSelected={
            filterContext
              ? {
                  filename: `historical_summary-${stringfiedFilterContext}`,
                  apiUrl: `/data/eventsummarylist/download?${listToQueryParam('id', selectedEventIds).toString()}`,
                }
              : undefined
          }
          pagination={events}
          exportFilename={`historical_summary${stringfiedFilterContext ? '-' + stringfiedFilterContext : ''}`}
          pdfHeader={pdfHeader}
          page={page}
          exportOptions={exportOptions.map((option) => {
            return { ...option, checked: option.id === id }
          })}
          noDataIcon={DataPlaceholderIcon}
          noDataMessage={DataPlaceholderMessage}
        />
      </div>
    </>
  )
}
