import { queryClient } from '@config/react-query'
import { ApiFetch } from '@services/api'
import { EventEntityWithSeries } from '@types'

export const fetchEventWithSeries = async (apiFetch: ApiFetch, selectedEventId: number) => {
  const event = await queryClient.fetchQuery({
    queryKey: ['event', selectedEventId, 'series'],
    queryFn: () => apiFetch(`/data/event/${selectedEventId}/series`) as Promise<EventEntityWithSeries>,
  })

  return event
}
