import { CustomEventFilterGroups } from '@components/EventContent/InsightsAndTrends/TimeSeriesChartCard/CustomEventsBenchmark'
import { BenchmarkOptionId } from '@components/EventContent/types'
import { queryClient } from '@config/react-query'
import { ApiFetch } from '@services/api'
import { BenchmarkDataFilter, EventDataSliceStoreSetFunction, EventFilter } from '@stores/slices/eventDataSlice'
import { CompetitorFilters } from '@stores/slices/eventDataSlice/competingEvents/types'
import { competitorFiltersToQueryParams } from '@stores/slices/eventDataSlice/competingEvents/utils'
import { filterFieldsToPageFilters, filtersToEventFilter } from '@stores/tourMarketingStore/tourMarketingStoreUtils'
import {
  CompetitorSummaryData,
  SecondaryMarketPriceAndTierData,
  SecondaryMarketTimeSeriesData,
  SecondaryMarketTimeSeriesInventoryBySectionData,
  SecondaryMarketTimeSeriesInventoryData,
  SecondaryMarketTimeSeriesInventoryDataV2,
  SecondaryMarketTimeSeriesInventoryDataV3,
  StarEventSummaryEntity,
  TimeSeriesEntityWithEvent,
} from '@types'

export const singleEventFilterToQueryParams = (filter: EventFilter, daysFromEvent: number | undefined = undefined) => {
  const params = new URLSearchParams()
  params.append('artist', filter.artist)
  params.append('venue', filter.venue)
  params.append('perfdate', filter.date)
  if (daysFromEvent !== undefined) params.append('days_from_event_end', daysFromEvent.toString())
  return params
}

export const fetchEventSummaryData = async <T>(
  set: EventDataSliceStoreSetFunction<T>,
  apiFetch: ApiFetch,
  filter: EventFilter,
) => {
  const eventSummaryData: StarEventSummaryEntity = await apiFetch(
    `/data/eventsummary?` + singleEventFilterToQueryParams(filter).toString(),
  )

  set({ eventSummaryData })
}

export const fetchTimeSeriesData = async <T>(
  set: EventDataSliceStoreSetFunction<T>,
  apiFetch: ApiFetch,
  filter: EventFilter,
) => {
  const timeSeriesEntity: TimeSeriesEntityWithEvent[] = await apiFetch(
    `/data/timeseries?` + singleEventFilterToQueryParams(filter).toString(),
  )

  set({ timeSeriesEntity })
}

export const fetchSecondaryMarketTimeSeriesData = async <T>(
  set: EventDataSliceStoreSetFunction<T>,
  apiFetch: ApiFetch,
  filter: EventFilter,
) => {
  const secondaryMarketTimeSeriesData: SecondaryMarketTimeSeriesData[] = await apiFetch(
    `/data/secondary_market_time_series?` + singleEventFilterToQueryParams(filter).toString(),
  )

  set({ secondaryMarketTimeSeriesData })
}

export const fetchSecondaryMarketPriceAndTierData = async <T>(
  set: EventDataSliceStoreSetFunction<T>,
  apiFetch: ApiFetch,
  filter: EventFilter,
) => {
  const secondaryMarketPriceAndTierData: SecondaryMarketPriceAndTierData[] = await apiFetch(
    `/data/secondary_market_price_and_tier?` + singleEventFilterToQueryParams(filter).toString(),
  )

  set({ secondaryMarketPriceAndTierData })
}

export const fetchSecondaryMarketTimeSeriesInventoryData = async <T>(
  set: EventDataSliceStoreSetFunction<T>,
  apiFetch: ApiFetch,
  filter: EventFilter,
) => {
  const secondaryMarketTimeSeriesInventoryData: SecondaryMarketTimeSeriesInventoryData[] = await apiFetch(
    `/data/secondary_market_time_series_inventory?` + singleEventFilterToQueryParams(filter).toString(),
  )

  set({ secondaryMarketTimeSeriesInventoryData })
}

export const fetchSecondaryMarketTimeSeriesInventoryDataV2 = async <T>(
  set: EventDataSliceStoreSetFunction<T>,
  apiFetch: ApiFetch,
  filter: EventFilter,
  daysFromEvent: number | undefined = undefined,
) => {
  const secondaryMarketTimeSeriesInventoryDataV2: SecondaryMarketTimeSeriesInventoryDataV2 = await apiFetch(
    `/data/secondary_market_time_series_inventory/v2?` +
      singleEventFilterToQueryParams(filter, daysFromEvent).toString(),
  )

  set({
    secondaryMarketTimeSeriesInventoryDataV2,
  })
  //   if (!daysFromEvent)
  //     set({
  //       secondaryMarketMaxDayFromEvent:
  //         secondaryMarketTimeSeriesInventoryDataV2.days.length > 0
  //           ? secondaryMarketTimeSeriesInventoryDataV2.days[0].event.days_from_event
  //           : undefined,

  //       secondaryMarketMinDayFromEvent:
  //         secondaryMarketTimeSeriesInventoryDataV2.days.length > 0
  //           ? secondaryMarketTimeSeriesInventoryDataV2.days[secondaryMarketTimeSeriesInventoryDataV2.days.length - 1]
  //               .event.days_from_event
  //           : undefined,
  //     })
}

export const fetchSecondaryMarketTimeSeriesInventoryDataV3 = async <T>(
  set: EventDataSliceStoreSetFunction<T>,
  apiFetch: ApiFetch,
  filter: EventFilter,
) => {
  const secondaryMarketTimeSeriesInventoryDataV3: SecondaryMarketTimeSeriesInventoryDataV3 = await apiFetch(
    `/data/secondary_market_time_series_inventory/v3?` + singleEventFilterToQueryParams(filter).toString(),
  )

  set({
    secondaryMarketTimeSeriesInventoryDataV3,
  })
}
export const fetchSecondaryMarketTimeSeriesInventoryBySectionData = async <T>(
  set: EventDataSliceStoreSetFunction<T>,
  apiFetch: ApiFetch,
  filter: EventFilter,
  daysFromEvent: number | undefined = undefined,
) => {
  const secondaryMarketTimeSeriesInventoryBySectionData: {
    min_days_from_event: number
    max_days_from_event: number
    items: SecondaryMarketTimeSeriesInventoryBySectionData[]
  } = await apiFetch(
    `/data/secondary_market_time_series_inventory_by_section/?` +
      singleEventFilterToQueryParams(filter, daysFromEvent).toString(),
  )

  set({
    secondaryMarketTimeSeriesInventoryBySectionData: secondaryMarketTimeSeriesInventoryBySectionData.items,
    secondaryMarketMaxDayFromEvent: secondaryMarketTimeSeriesInventoryBySectionData.max_days_from_event,
    secondaryMarketMinDayFromEvent: secondaryMarketTimeSeriesInventoryBySectionData.min_days_from_event,
  })
}

export const fetchCompetitorsSumaryData = async <T>(
  set: EventDataSliceStoreSetFunction<T>,
  apiFetch: ApiFetch,
  filter: CompetitorFilters,
) => {
  const apiUrl = '/data/competitor_density/summary'
  const queryParams = competitorFiltersToQueryParams(filter)

  const competitorsSummaryData = await queryClient.fetchQuery({
    queryKey: [apiUrl, queryParams.toString()],
    queryFn: () => apiFetch(`${apiUrl}?${queryParams.toString()}`, {}) as Promise<CompetitorSummaryData[]>,
  })

  set({ competitorsSummaryData })
}

const fetchCustomTimeSeriesData = async (apiFetch: ApiFetch, filter: EventFilter) => {
  const data: TimeSeriesEntityWithEvent[] = await apiFetch(`/data/timeseries?` + singleEventFilterToQueryParams(filter))
  return data
}

export const fetchMultipleCustomTimeSeriesData = async <T>(
  set: EventDataSliceStoreSetFunction<T>,
  apiFetch: ApiFetch,
  customEvents: CustomEventFilterGroups,
) => {
  const multipleCustomTimeSeriesEntities = await Promise.all(
    customEvents.map((customEvent) =>
      fetchCustomTimeSeriesData(apiFetch, filtersToEventFilter(filterFieldsToPageFilters(customEvent))),
    ),
  )

  set({ multipleCustomTimeSeriesEntities })
}

export const fetchBenchmarkData = async <T>(
  set: EventDataSliceStoreSetFunction<T>,
  apiFetch: ApiFetch,
  benchmark?: BenchmarkDataFilter,
) => {
  if (benchmark) {
    set({ selectedBenchmark: benchmark.option })
    if (benchmark.events && benchmark.option?.id === BenchmarkOptionId.CustomEvents) {
      set({ customEvents: benchmark.events })
      await fetchMultipleCustomTimeSeriesData(set, apiFetch, benchmark.events)
    }
  } else {
    set({ selectedBenchmark: null, customEvents: [] })
  }
}
