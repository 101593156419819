import cloneDeep from 'lodash/cloneDeep'
import qs from 'qs'
import { BenchmarkOption } from '@components/EventContent'
import { CustomEventFilterGroups } from '@components/EventContent/InsightsAndTrends/TimeSeriesChartCard/CustomEventsBenchmark'
import { Item } from '@components/RemoteSelect'
import { TourPlanningContext } from '@stores/bookmarkStore/utils/types'
import { EventSearchContext, Filters } from '@stores/eventSearchStore'
import { createFilterInstances } from '@stores/eventSearchStore/utils'
import {
  DEFAULT_SECONDARY_MARKET_CHART_TYPE,
  EventFilter,
  SecondaryMarketChartType,
} from '@stores/slices/eventDataSlice'
import {
  customEventObjectFilterToFilterFields,
  filterFieldsToCustomEventObjectFilter,
} from '@stores/tourMarketingStore/queryParams'

export const filtersToQueryParams = (filters: Filters) => {
  const urlSearchParams = new URLSearchParams()
  filters.forEach((filter) => {
    const params = filter.toParams()
    Object.entries(params).forEach(([key, value]) => {
      if (value != null) {
        if (Array.isArray(value)) {
          value.forEach((v) => urlSearchParams.append(key, v.toString()))
        } else {
          urlSearchParams.append(key, value.toString())
        }
      }
    })
  })

  return urlSearchParams
}

export const urlToContext = (searchParams: URLSearchParams | string): EventSearchContext => {
  let filters: Filters = []
  const paramsData = qs.parse(searchParams.toString(), { parseArrays: true })
  if ('filters' in paramsData) {
    const parsedFilters = paramsData.filters as any[]
    filters = createFilterInstances(parsedFilters)
  }

  let singleEventFilter = null
  if ('singleEventFilter' in paramsData && paramsData.singleEventFilter) {
    singleEventFilter = paramsData.singleEventFilter as EventFilter
  }

  let benchmark = undefined
  if ('benchmark' in paramsData) {
    const benchmarkParam = paramsData.benchmark as qs.ParsedQs
    benchmark = {
      option: benchmarkParam.option as unknown as BenchmarkOption,
      events: [] as CustomEventFilterGroups,
    }

    if ('events' in benchmarkParam && Array.isArray(benchmarkParam.events) && benchmarkParam.events.length > 0) {
      benchmark.events = benchmarkParam.events.map(
        customEventObjectFilterToFilterFields,
      ) as unknown as CustomEventFilterGroups
    }
  }

  let competitorsFilters = undefined
  if ('competitorsFilters' in paramsData) {
    const competitorParams = paramsData.competitorsFilters as qs.ParsedQs
    competitorsFilters = {
      category: (competitorParams.category as Item[]) || [],
      subcategory: (competitorParams.subcategory as Item[]) || [],
      distance: (competitorParams.distance as Item) || null,
      time: (competitorParams.time as Item) || null,
    }
  }

  let secondaryMarket = {
    chartType: DEFAULT_SECONDARY_MARKET_CHART_TYPE,
  }
  if ('secondaryMarket' in paramsData) {
    const secondaryMarketParams = paramsData.secondaryMarket as qs.ParsedQs
    if ('chartType' in secondaryMarketParams) {
      secondaryMarket = {
        chartType: (secondaryMarketParams.chartType as SecondaryMarketChartType) || DEFAULT_SECONDARY_MARKET_CHART_TYPE,
      }
    }
  }

  return { filters, singleEventFilter, benchmark, competitorsFilters, secondaryMarket }
}

export const contextToUrl = (context: EventSearchContext): string => {
  const ctx = cloneDeep(context) as any
  ctx.extended = true
  if (ctx.secondaryMarket?.chartType === DEFAULT_SECONDARY_MARKET_CHART_TYPE) {
    delete ctx['secondaryMarket']
  }
  if (context.benchmark?.events) {
    ctx.benchmark.events = context.benchmark.events.map(filterFieldsToCustomEventObjectFilter)
  }
  const url = qs.stringify(ctx, { skipNulls: true, format: 'RFC1738' })
  return url
}

export const benchmarkToUrl = (context: EventSearchContext): string => {
  const ctx = cloneDeep(context) as any
  ctx.extended = true
  if (ctx.secondaryMarket?.chartType === DEFAULT_SECONDARY_MARKET_CHART_TYPE) {
    delete ctx['secondaryMarket']
  }
  delete ctx['filters']
  delete ctx['singleEventFilter']
  if (context.benchmark?.events) {
    ctx.benchmark.events = context.benchmark.events.map(filterFieldsToCustomEventObjectFilter)
  }
  const url = qs.stringify(ctx, { skipNulls: true, format: 'RFC1738' })
  return url
}

export const tourPlanningContextToUrl = (context: TourPlanningContext): string => {
  const ctx = cloneDeep(context) as any
  ctx.extended = true
  if (ctx.secondaryMarket?.chartType === DEFAULT_SECONDARY_MARKET_CHART_TYPE) {
    delete ctx['secondaryMarket']
  }
  const url = qs.stringify(ctx, { skipNulls: true, format: 'RFC1738' })
  return url
}
