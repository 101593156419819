import { Button, FileInput, Label, Modal, TextInput, Textarea, useTheme } from 'carbonarc-ui'
import { useTranslation } from 'react-i18next'
import { ValidationError, useForm } from '@formspree/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import { cn } from '@utils/className'

type ContactFormProps = {
  show: boolean
  onClose: () => void
}

export const ContactForm = ({ show, onClose }: ContactFormProps) => {
  const [state, handleSubmit, reset] = useForm('mzbnogaj')
  const modalTheme = useTheme().theme.modal
  const { t } = useTranslation('common')
  const formReset = () => {
    onClose()
    reset()
  }
  return (
    <Modal
      show={show}
      position="top-center"
      onClose={formReset}
      size="md"
      theme={{
        root: {
          positions: {
            'top-center': cn(modalTheme.root.positions['top-center'], 'pt-[100px]'),
          },
        },
      }}
    >
      <Modal.Header
        theme={{
          title: cn(modalTheme.header.title, 'text-lg font-semibold'),
        }}
      >
        {t('feedback.modal.title')}
      </Modal.Header>
      {state.succeeded ? (
        <Modal.Body>
          <div className="flex flex-col items-center gap-5 text-xs font-normal text-gray-500 dark:text-gray-400">
            <div className="bg-gray-50 dark:bg-gray-600 rounded-full w-24 h-24 flex items-center justify-center">
              <CheckIcon className="w-10 h-10 text-gray-200 dark:text-gray-500 stroke-2" />
            </div>
            {t('feedback.modal.success_message')}
          </div>
        </Modal.Body>
      ) : (
        <form onSubmit={handleSubmit}>
          <Modal.Body className="flex flex-col gap-4">
            <div>
              <div className="mb-2 block">
                <Label
                  htmlFor="email"
                  value={t('feedback.modal.form.email_label')}
                  className="text-sm font-medium text-gray-900 dark:text-gray-100"
                />
              </div>
              <TextInput
                id="email"
                name="email"
                placeholder={t('feedback.modal.form.email_placeholder')}
                required
                type="text"
              />
              <ValidationError prefix="Email" field="email" errors={state.errors} />
            </div>
            <div>
              <div className="mb-2 block">
                <Label
                  htmlFor="details"
                  value={t('feedback.modal.form.details_label')}
                  className="text-sm font-medium text-gray-900 dark:text-gray-100"
                />
              </div>
              <Textarea
                id="details"
                name="details"
                required
                placeholder={t('feedback.modal.form.details_placeholder')}
              />
              <ValidationError prefix="Details" field="details" errors={state.errors} />
            </div>
            <div>
              <div className="mb-2 block">
                <Label
                  htmlFor="file"
                  value={t('feedback.modal.form.upload_label')}
                  className="text-sm font-medium text-gray-900 dark:text-gray-100"
                />
              </div>
              <FileInput id="file" name="file" accept=".gif,.jpg,.jpeg,.png,.webp" />
              <span className="text-xs font-normal text-gray-500 dark:text-gray-400">
                {t('feedback.modal.form.upload_helper')}
              </span>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="default" type="submit" disabled={state.submitting} isLoading={state.submitting}>
              {t('feedback.modal.save')}
            </Button>
            <Button variant="outline" type="button" onClick={formReset} disabled={state.submitting}>
              {t('feedback.modal.cancel')}
            </Button>
          </Modal.Footer>
        </form>
      )}
    </Modal>
  )
}
