import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as CSVIcon } from '@assets/csv-icon.svg'
import { ReactComponent as PDFIcon } from '@assets/pdf-icon.svg'
import { CheckboxOption } from '@components/Export'
import { ExportModal } from '@components/Export/ExportModal'
import { useFlag } from '@config/site/app'
import { Flags } from '@config/site/app/flags'
import { Listbox, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { ExportCsvOption, ExportCsvProps } from './ExportCSVOption'

export enum ExportOptionId {
  ExportPDF = 'exportPDF',
  ExportAllCSV = 'exportAllCSV',
  ExportCSV = 'exportCSV',
}

export interface ExportOption {
  id: ExportOptionId
  label: string
  icon: React.ElementType
  show: boolean
  onClick?(): void
}

interface PDFOptions {
  id: string
  options?: CheckboxOption[]
}

interface ExportDropdownProps {
  pdfOptions?: PDFOptions
  csvOptions?: { all?: ExportCsvProps; selected?: ExportCsvProps }
}

export function ExportDropdown(props: ExportDropdownProps) {
  const enabled = useFlag(Flags.ENABLE_EXPORT)
  const { pdfOptions, csvOptions } = props
  const { t } = useTranslation('')
  const [open, setOpen] = useState(false)
  const [showExportModal, setShowExportModal] = useState(false)
  const options: ExportOption[] = [
    {
      id: ExportOptionId.ExportPDF,
      label: t('export.pdf'),
      icon: PDFIcon,
      show: !pdfOptions,
      onClick: () => {
        if (!pdfOptions) return

        setShowExportModal(true)
        setOpen(false)
      },
    },
  ]
  if (!enabled) return null

  return (
    <>
      <div className="flex justify-end items-end gap-2 group-[.pdf-report]:hidden">
        <Listbox as="div" className="relative">
          <Listbox.Button
            onClick={() => setOpen(!open)}
            className="relative cursor-pointer w-full min-w-[90px] rounded-lg bg-white py-2 px-3 pr-8 border border-gray-300 text-gray-500 text-xs font-medium text-left dark:bg-gray-700 dark:border-gray-500 dark:text-gray-300 overflow-hidden"
          >
            <span className="block break-all text-ellipsis">Export</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon className="h-4 w-4 stroke-2" aria-hidden="true" />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            show={open}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-10 right-0 border border-gray-300 p-1 mt-1 w-[160px] overflow-auto rounded-lg bg-white py-1 text-base shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm dark:bg-gray-700 dark:border-gray-500">
              {options
                .filter((option) => !option.show)
                .map((option) => (
                  <Listbox.Option
                    key={option.id}
                    value={option}
                    onClick={option.onClick}
                    className={({ active, selected }) =>
                      `relative cursor-pointer w-full select-none py-2 px-3 rounded text-gray-700 dark:text-gray-300 text-xs
                    flex items-center justify-start
                    ${
                      selected
                        ? 'font-medium bg-gray-100 dark:bg-gray-800'
                        : active
                        ? 'bg-gray-50 dark:bg-gray-800/50'
                        : 'bg-white dark:bg-gray-700'
                    }
                    `
                    }
                  >
                    {<option.icon className="w-5 h-5 mr-2" />}
                    {option.label}
                  </Listbox.Option>
                ))}
              {csvOptions && csvOptions.all && (
                <ExportCsvOption
                  {...csvOptions.all}
                  option={{
                    id: ExportOptionId.ExportAllCSV,
                    label: t('export.csvAll'),
                    icon: CSVIcon,
                    show: !csvOptions.all,
                  }}
                  setOpen={setOpen}
                />
              )}
              {csvOptions && csvOptions.selected && (
                <ExportCsvOption
                  {...csvOptions.selected}
                  option={{
                    id: ExportOptionId.ExportCSV,
                    label: t('export.csv'),
                    icon: CSVIcon,
                    show: !csvOptions.selected,
                  }}
                  setOpen={setOpen}
                />
              )}
            </Listbox.Options>
          </Transition>
        </Listbox>
      </div>
      {showExportModal && pdfOptions?.id && (
        <ExportModal
          show={showExportModal}
          setShow={setShowExportModal}
          pageId={pdfOptions?.id}
          options={pdfOptions?.options || []}
        />
      )}
    </>
  )
}
