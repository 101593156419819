import i18n from '@config/react-i18next'
import { Flags } from '@config/site/app/flags'
import { BookOpenIcon, CheckCircleIcon } from '@heroicons/react/24/outline'

const t = i18n.t

export type PageData = {
  label: string
  path: string
  badge?: string
  icon?: React.ElementType
  flag?: Flags
}

export const Divider = ''

export enum Page {
  OVERVIEW = 'overview',
  EVENT_INSIGHTS = 'event-insights',
  ARTIST_VENUE_INSIGHTS = 'artist-venue-insights',
  UPCOMING_EVENTS = 'upcoming-events',
  PREDICTION_ASSESSMENT = 'prediction-assessment',
  DATA_HEALTH = 'data-health',
}

export enum ExternalPage {
  GITBOOK = 'gitbook',
}

export const pageData: Record<Page, PageData> = {
  [Page.OVERVIEW]: {
    label: t('overview:title'),
    path: '/overview',
  },
  [Page.EVENT_INSIGHTS]: {
    label: 'Event Insights',
    path: '/event-insights',
    flag: Flags.SHOW_EVENT_INSIGHTS,
  },
  [Page.ARTIST_VENUE_INSIGHTS]: {
    label: t('tour_planning:title'),
    path: '/artist-venue-insights',
  },
  [Page.UPCOMING_EVENTS]: {
    label: 'Upcoming Events',
    path: '/upcoming-events',
    flag: Flags.SHOW_UPCOMING_EVENTS,
  },
  [Page.PREDICTION_ASSESSMENT]: {
    label: 'Predictions',
    path: '/prediction-assessment',
    badge: 'Beta',
    flag: Flags.SHOW_PREDICTIONS,
  },
  [Page.DATA_HEALTH]: {
    label: t('data_health'),
    path: '/data-health',
    icon: CheckCircleIcon,
  },
}

export const externalPageData: Record<ExternalPage, Omit<PageData, 'path'>> = {
  [ExternalPage.GITBOOK]: {
    label: t('docs'),
    icon: BookOpenIcon,
  },
}
